const popoverContent = {
  wbgtData: {
    title: 'WBGT',
    content: `暑さ指数(WBGT)は、Wet-Bulb Globe Temperature（湿球黒球温度）の略称で、下記の測定装置の３種類に測定値（黒球温度、湿球温度及び乾球温度）をもとに算出されます。

出典：「熱中症予防情報サイト」（環境省） 
https://www.wbgt.env.go.jp/doc_observation.php`,
  },
  globeTemperature: {
    title: '黒球温度',
    content: `黒球温度（GT:Globe Temperature）は、黒色に塗装された薄い銅板の球（中は空洞、直径約15cm）の中心に温度計を入れて観測します。黒球の表面はほとんど反射しない塗料が塗られています。この黒球温度は、直射日光にさらされた状態での球の中の平衡温度を観測しており、弱風時に日なたにおける体感温度と良い相関があります。

出典：「熱中症予防情報サイト」（環境省） 
https://www.wbgt.env.go.jp/doc_observation.php`,
  },
};

export { popoverContent };
