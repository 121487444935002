import string from 'util/string';
import './WBGTThreshold.scss';

const defaultThreshold = [31, 28, 25, 21];

const WBGTThreshold = () => {
  return (
    <div>
      <div className={`state-title`}>
        <div className={`state-title-1`}>
          <div className="title">{string.STATE_NORMAL}</div>
          <div className="title2"> {`${defaultThreshold[3]}℃未満`}</div>
        </div>
        <div className={`state-title-2`}>
          <div className="title">{string.STATE_WARNING}</div>
          <div className="title2">{`${defaultThreshold[3]}℃-${defaultThreshold[2]}℃`}</div>
        </div>
        <div className={`state-title-3`}>
          <div className="title">{string.STATE_VIGILANCE}</div>
          <div className="title2">{`${defaultThreshold[2]}℃-${defaultThreshold[1]}℃`}</div>
        </div>
        <div className={`state-title-4`}>
          <div className="title">{string.STATE_CAUTION}</div>
          <div className="title2">
            {`${defaultThreshold[1]}℃-${defaultThreshold[0]}℃`}
          </div>
        </div>
        <div className={`state-title-5`}>
          <div className="title">{string.STATE_DANGER}</div>
          <div className="title2">{`${defaultThreshold[0]}℃以上`}</div>
        </div>
      </div>
    </div>
  );
};

export default WBGTThreshold;
