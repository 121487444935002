import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import './Search.scss';

const Search = ({ handleSearch, placeholder }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    handleSearch(value);
  }, [value]);

  // const search = (value) => {
  //   // handleSearch(value);
  // };
  return (
    <>
      <div className="search">
        <input
          placeholder={placeholder}
          type="text"
          className="input form-control"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          // onKeyPress={(event) => {
          //   if (event.key === 'Enter') {
          //     search(value);
          //   }
          // }}
        />
        <span className="icon">
          <FaSearch />
        </span>
      </div>
    </>
  );
};

export default Search;
